import { API, graphqlOperation } from 'aws-amplify';

// all queries are indicated by "get"; mutations begin with "update", "create", "delete", etc.
export default {
  async getSchema (queryObj) {
    return await API.graphql(graphqlOperation(queryObj.query));
  },
  async getDevices (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.query));
  },
  async getDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.querySingleDevice, { deviceId: queryObj.deviceId }));
  },
  async createDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.addMutation, queryObj));
  },
  async claimDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.claimMutation, queryObj));
  },
  async updateDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.updateMutation, queryObj));
  },
  async deleteDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.deleteMutation, queryObj));
  },
  async unclaimDevice (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.unclaimMutation, queryObj));
  },
  async createJob (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.jobsConfig.addMutation, queryObj));
  },
  async getJobs (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.jobsConfig.query, queryObj));
  },
  async cancelJob (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.jobsConfig.cancelMutation, queryObj));
  },
  async getSessions (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.query));
  },
  async createSession (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.addMutation, queryObj));
  },
  async deleteSession (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.deleteSession, queryObj));
  },
  async getConsumables (context) {
    return await API.graphql(graphqlOperation(context.state.consumablesConfig.query));
  },
  async createConsumable (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.consumablesConfig.addMutation, queryObj));
  },
  async updateConsumable (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.consumablesConfig.updateMutation, queryObj));
  },
  async getLogs (context) {
    return await API.graphql(graphqlOperation(context.state.logsConfig.query));
  },
  async getIntegrations (context) {
    return await API.graphql(graphqlOperation(context.state.integrationsConfig.query));
  },
  async authorizeIntegration (context, mutationObj) {
    return await API.graphql(graphqlOperation(context.state.integrationsConfig.addMutation, mutationObj));
  },
  async revokeIntegration (context, id) {
    return await API.graphql(graphqlOperation(context.state.integrationsConfig.revokeMutation, id));
  },
  async updateUser (context, queryObj) {
    return await API.graphql(graphqlOperation(context.state.userConfig.updateMutation, queryObj));
  },
  async deleteUser (context) {
    return await API.graphql(graphqlOperation(context.state.userConfig.deleteMutation));
  },
  async getDeviceTypes (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryTypes));
  },
  async getConsumablesTypes (context) {
    return await API.graphql(graphqlOperation(context.state.consumablesConfig.queryTypes));
  },
  async getConsumablesStates (context) {
    return await API.graphql(graphqlOperation(context.state.consumablesConfig.queryStates));
  },
  async getDeviceStatuses (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryStatuses));
  },
  async getOralBVoiceConfigs (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryVoiceConfig));
  },
  async getBraunHeadEnums (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.queryHead));
  },
  async getBraunClientEnums (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.queryClient));
  },
  async getBraunSessionStatusEnums (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.querySessionStatus));
  },
  async getBraunSessionTypeEnums (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.querySessionType));
  },
  async getBraunLogLevelEnums (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryLogLevel));
  },
  async getOralBBrushingModeTypes (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryBrushingModeTypes));
  },
  async getValidationErrors (context) {
    return await API.graphql(graphqlOperation(context.state.validationsConfig.query));
  },
  async getGilletteFirmwareModel (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryFirmwaremode));
  },
  async getGilletteHandleDocked (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryHandleDocked));
  },
  async getGilletteHandleMode (context) {
    return await API.graphql(graphqlOperation(context.state.devicesConfig.queryHandleMode));
  },
  async getGilletteCartridgeChangeTypes (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.queryCartridgeChange));
  },
  async getGilletteDiagnosticResultTypes (context) {
    return await API.graphql(graphqlOperation(context.state.sessionsConfig.queryDiagnosticResult));
  },
  async getTaskTypes (context) {
    return await API.graphql(graphqlOperation(context.state.jobsConfig.queryTaskTypes));
  }
};
