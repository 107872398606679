export const getDeviceTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'BETAMAX'
        },
        {
          name: 'BOOMBOX_ALEXA'
        },
        {
          name: 'BOOMBOX_GOOGLE'
        },
        {
          name: 'D21_1_MODE'
        },
        {
          name: 'D21_2A_MODE'
        },
        {
          name: 'D21_2B_MODE'
        },
        {
          name: 'D21_3_MODE'
        },
        {
          name: 'D21_3_MODE_WHITENING'
        },
        {
          name: 'D21_4_MODE'
        },
        {
          name: 'D36_5_MODE'
        },
        {
          name: 'D36_6_MODE'
        },
        {
          name: 'D601_1_MODE'
        },
        {
          name: 'D601_2A_MODE'
        },
        {
          name: 'D601_2B_MODE'
        },
        {
          name: 'D601_3A_MODE'
        },
        {
          name: 'D601_3B_MODE'
        },
        {
          name: 'D601_4_MODE'
        },
        {
          name: 'D601_5_MODE'
        },
        {
          name: 'D700_4_MODE'
        },
        {
          name: 'D700_5_MODE'
        },
        {
          name: 'D700_6_MODE'
        },
        {
          name: 'D701_5_MODE'
        },
        {
          name: 'D701_6_MODE'
        },
        {
          name: 'D706_5_MODE'
        },
        {
          name: 'D706_6_MODE'
        },
        {
          name: 'M6'
        },
        {
          name: 'M6_BIGTI'
        },
        {
          name: 'M7'
        },
        {
          name: 'M7_BIGTI'
        },
        {
          name: 'M8'
        },
        {
          name: 'M8_BIGTI'
        },
        {
          name: 'M9'
        },
        {
          name: 'M9_BIGTI'
        },
        {
          name: 'SEAGATE'
        }
      ]
    }
  }
};

export const getDeviceStatusesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'FACTORY'
        },
        {
          name: 'PROVISIONED'
        }
      ]
    }
  }
};

export const getOralBVoiceConfigsData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DISABLED'
        },
        {
          name: 'ENABLED'
        }
      ]
    }
  }
};

export const getOralBBrushingModeTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'DAILY_CLEAN'
        },
        {
          name: 'DEEP_CLEAN'
        },
        {
          name: 'MASSAGE'
        },
        {
          name: 'OFF'
        },
        {
          name: 'PRO_CLEAN'
        },
        {
          name: 'SENSITIVE'
        },
        {
          name: 'SUPER_SENSITIVE'
        },
        {
          name: 'TONGUE_CLEAN'
        },
        {
          name: 'WHITENING'
        }
      ]
    }
  }
};

export const getTaskTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'CLEAR_CONFIG'
        },
        {
          name: 'GET_STATUS'
        },
        {
          name: 'GET_SYSLOG'
        },
        {
          name: 'OTA_UPDATE'
        },
        {
          name: 'REBOOT'
        }
      ]
    }
  }
};

export const getConsumablesTypesData = {
  data: {
    __type: {
      enumValues: [
        {
          name: 'CROSS_ACTION'
        },
        {
          name: 'FLOSS_ACTION'
        },
        {
          name: 'OTHER'
        },
        {
          name: 'PRECISION'
        },
        {
          name: 'PRO_GUM'
        },
        {
          name: 'SENSITIVE'
        },
        {
          name: 'THREE_D_WHITE'
        }
      ]
    }
  }
};
