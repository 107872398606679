import Vue from 'vue';
import Vuex from 'vuex';
import { Auth } from 'aws-amplify';
import brandConfig from '@/util/brandConfigs';
import currentBrand from '@/util/getCurrentBrand';
import brandConstants from '@/util/brandConstants';
import { appData } from '@/api/appData';

const {
  getSchema,
  getDevices, getDevice, createDevice, claimDevice, updateDevice, deleteDevice, unclaimDevice,
  createJob, getJobs, cancelJob,
  getSessions, createSession, deleteSession,
  getConsumables, createConsumable, updateConsumable,
  getLogs,
  getIntegrations,
  authorizeIntegration,
  revokeIntegration,
  updateUser, deleteUser,
  getDeviceTypes,
  getConsumablesTypes,
  getConsumablesStates,
  getDeviceStatuses,
  getOralBVoiceConfigs, getOralBBrushingModeTypes, getValidationErrors,
  getBraunHeadEnums, getBraunSessionStatusEnums,
  getBraunSessionTypeEnums, getBraunLogLevelEnums, getBraunClientEnums,
  getGilletteFirmwareModel, getGilletteHandleDocked, getGilletteHandleMode, getGilletteCartridgeChangeTypes, getGilletteDiagnosticResultTypes,
  getTaskTypes
} = appData;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    brand: 'Oral-B NA',
    devicesConfig: {},
    validationsConfig: {},
    jobsConfig: {},
    sessionsConfig: {},
    consumablesConfig: {},
    userConfig: {},
    logsConfig: {},
    brandConstants,
    signedIn: false,
    user: {},
    loading: false,
    loadingStatus: 'Nothing has loaded yet',
    devices: [],
    devicesError: [],
    selectedDevice: {},
    sessions: [],
    validations: [],
    validationsErrors: [],
    sessionsError: [],
    consumables: [],
    consumablesError: [],
    logs: [],
    logsError: [],
    availableIntegrations: [],
    configuredIntegrations: [],
    integrationsError: [],
    deviceTypes: [],
    consumableTypes: [],
    consumableStates: [],
    deviceStatuses: [],
    oralBVoiceConfigs: [],
    oralBBrushingModeTypes: [],
    gilletteFirmwareModeTypes: [],
    gilletteHandleDockedTypes: [],
    gilletteHandleModeTypes: [],
    gilletteCartridgeChangeTypes: [],
    gilletteDiagnosticResultTypes: [],
    braunAnalyticsPreferences: [],
    braunCreatedBy: [],
    braunDeviceEvents: [],
    braunHeadTypes: [],
    braunSessionStatus: [],
    braunSessionType: [],
    braunLogLevel: [],
    braunClient: [],
    taskTypes: [],
    downloadSchema: {},
    allowedPaths: ['/auth', '/logout']
  },
  mutations: {
    SET_LOADING_BOOL (state, bool) {
      state.loading = bool;
    },
    SET_LOADING_STATUS (state, status) {
      state.loadingStatus = status;
    },
    UPDATE_USER (state, sessionInfo) {
      state.user = sessionInfo;
    },
    SET_DEVICES (state, devices) {
      state.devices = devices;
    },
    SET_DEVICES_ERRORS (state, errors) {
      state.devicesError = errors;
    },
    SET_SELECTED_DEVICE (state, device) {
      state.selectedDevice = device;
    },
    UPDATE_SELECTED_DEVICE (state, device) {
      state.selectedDevice = device;
    },
    SET_SESSIONS (state, sessions) {
      state.sessions = sessions;
    },
    SET_VALIDATIONS (state, validations) {
      state.validations = validations.data.validations;
    },
    SET_VALIDATIONS_ERRORS (state, errors) {
      state.validationsError = errors;
    },
    SET_SESSIONS_ERRORS (state, errors) {
      state.sessionsError = errors;
    },
    SET_CONSUMABLES (state, consumables) {
      state.consumables = consumables;
    },
    SET_CONSUMABLES_ERRORS (state, errors) {
      state.consumablesError = errors;
    },
    SET_LOGS (state, logs) {
      state.logs = logs;
    },
    SET_LOGS_ERRORS (state, errors) {
      state.logsError = errors;
    },
    SET_INTEGRATIONS (state, integrations) {
      state.availableIntegrations = integrations.available;
      state.configuredIntegrations = integrations.configured;
    },
    SET_INTEGRATIONS_ERRORS (state, errors) {
      state.integrationsError = errors;
    },
    UPDATE_STATUSES (state, statuses) {
      state.deviceStatuses = statuses;
    },
    UPDATE_VOICE_CONFIGS (state, types) {
      state.oralBVoiceConfigs = types;
    },
    UPDATE_BRUSHING_MODE_TYPES (state, types) {
      state.oralBBrushingModeTypes = types;
    },
    UPDATE_FIRMWARE_MODE (state, types) {
      state.gilletteFirmwareModeTypes = types;
    },
    UPDATE_HANDLE_DOCKED (state, types) {
      state.gilletteHandleDockedTypes = types;
    },
    UPDATE_HANDLE_MODE (state, types) {
      state.gilletteHandleModeTypes = types;
    },
    UPDATE_CARTRIDGE_CHANGE (state, types) {
      state.gilletteCartridgeChangeTypes = types;
    },
    UPDATE_DIAGNOSTIC_RESULTS (state, types) {
      state.gilletteDiagnosticResultTypes = types;
    },
    UPDATE_DEVICE_TYPES (state, types) {
      state.deviceTypes = types;
    },
    UPDATE_CONSUMABLE_TYPES (state, types) {
      state.consumableTypes = types;
    },
    UPDATE_CONSUMABLE_STATES (state, types) {
      state.consumableStates = types;
    },
    UPDATE_TASK_TYPES (state, types) {
      state.taskTypes = types;
    },
    UPDATE_ANALYTICS_PREFERENCES (state, types) {
      state.braunAnalyticsPreferences = types;
    },
    UPDATE_CREATED_BY (state, types) {
      state.braunCreatedBy = types;
    },
    UPDATE_DEVICE_EVENTS (state, types) {
      state.braunDeviceEvents = types;
    },
    UPDATE_HEAD_TYPES (state, types) {
      state.braunHeadTypes = types;
    },
    UPDATE_SESSION_STATUS (state, types) {
      state.braunSessionStatus = types;
    },
    UPDATE_SESSION_TYPE (state, types) {
      state.braunSessionType = types;
    },
    UPDATE_LOG_LEVEL (state, types) {
      state.braunLogLevel = types;
    },
    UPDATE_CLIENT (state, types) {
      state.braunClient = types;
    },
    SET_DOWNLOAD_SCHEMA (state, downloadSchema) {
      state.downloadSchema = downloadSchema;
    },
    SET_CONFIGS (state) {
      state.brand = currentBrand();
      state.devicesConfig = brandConfig[currentBrand()].devices;
      state.validationsConfig = brandConfig.agnostic.validations;
      state.jobsConfig = currentBrand().includes('Labs') ? brandConfig[currentBrand()].jobs : brandConfig.agnostic.jobs;
      state.sessionsConfig = brandConfig[currentBrand()].sessions;
      state.consumablesConfig = brandConfig[currentBrand()].consumables;
      state.userConfig = brandConfig[currentBrand()].user;
      state.logsConfig = brandConfig.agnostic.logs;
      state.integrationsConfig = brandConfig.agnostic.integrations;
    },
    RESET_STORE (state) {
      state.brand = '';
      state.devicesConfig = {};
      state.validationsConfig = {};
      state.sessionsConfig = {};
      state.consumablesConfig = {};
      state.logsConfig = {};
      state.integrationsConfig = {};
      state.userConfig = {};
      state.devices = [];
      state.devicesError = [];
      state.sessions = [];
      state.sessionsError = [];
      state.logs = [];
      state.logsError = [];
      state.availableIntegrations = [];
      state.configuredIntegrations = [];
      state.integrationsError = [];
    }
  },
  actions: {
    async getSchemaQuery (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading schema...');
      try {
        const schema = await getSchema(queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Schema loaded');
        context.commit('SET_DOWNLOAD_SCHEMA', schema);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
      }
    },

    async getDevicesQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading devices...');
      try {
        const devices = await getDevices(context);
        context.commit('SET_DEVICES', devices.data.devices);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'devices loaded');
        return devices;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    setDevicesErrors (context, errors) {
      context.commit('SET_DEVICES_ERRORS', errors);
    },

    async getSelectedDeviceQuery (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Retrieving device info...');
      try {
        const device = await getDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'device retrieved');
        const selectedDeviceLogs = context.state.logs.filter(log => log.deviceId === queryObj.deviceId);
        const selectedDeviceValidations = context.state.validations.filter(validation => validation.data.deviceId === queryObj.deviceId);
        const selectedDevice = {
          ...device.data.device,
          logs: selectedDeviceLogs,
          validations: selectedDeviceValidations
        };
        context.commit('SET_SELECTED_DEVICE', selectedDevice);
        return device;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        context.commit('SET_SELECTED_DEVICE', {});
        return err;
      }
    },

    async createDeviceMutation (context, queryObj) {
      // clean up deviceId
      queryObj.deviceId = queryObj.deviceId.toLowerCase().replace('-', '');

      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Creating device...');
      try {
        const device = await createDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Device successfully created');
        return device;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async claimDeviceMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Creating device...');
      try {
        const device = await claimDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Device successfully created');
        return device;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async updateDeviceMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Updating device...');
      try {
        const device = await updateDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Device has been updated');
        return device;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async deleteDeviceMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Deleting device...');
      try {
        const device = await deleteDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Device has been deleted');
        return device;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async unclaimDeviceMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Deleting device...');
      try {
        const device = await unclaimDevice(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Device has been deleted');
        return device;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async createJobMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Creating job...');
      try {
        const OTAjob = await createJob(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Job created!');
        return OTAjob;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async getJobsQuery (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Retrieving jobs...');
      try {
        const OTAjobs = await getJobs(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Jobs loaded');
        return OTAjobs;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async cancelJobsMutation (context, queryObj) {
      try {
        const cancelJobResponse = await cancelJob(context, queryObj);
        return cancelJobResponse;
      } catch (err) {
        return err;
      }
    },

    async getSessionsQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading sessions...');
      try {
        const sessions = await getSessions(context);
        context.commit('SET_SESSIONS', sessions.data.sessions.items);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Sessions loaded');
        return sessions;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async getValidationsQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading validations...');
      try {
        const validations = await getValidationErrors(context);
        context.commit('SET_VALIDATIONS', validations);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Validations loaded');
        return validations;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    setSessionsErrors (context, errors) {
      context.commit('SET_SESSIONS_ERRORS', errors);
    },

    async createSessionMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Creating session...');
      try {
        const session = await createSession(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Session created');
        return session;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async deleteSessionMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Deleting session...');
      try {
        const session = await deleteSession(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Session deleted');
        return session;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async getConsumablesQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading consumables...');
      try {
        const consumables = await getConsumables(context);
        context.commit('SET_CONSUMABLES', consumables.data.consumables.items);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Consumables loaded');
        return consumables;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async createConsumableMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Creating Consumable...');
      try {
        const session = await createConsumable(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Consumable created');
        return session;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async updateConsumableMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Updating Consumable...');
      try {
        const consumable = await updateConsumable(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Consumable updated');
        return consumable;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    setConsumablesErrors (context, errors) {
      context.commit('SET_CONSUMABLES_ERRORS', errors);
    },

    async getLogsQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading logs...');
      try {
        const logs = await getLogs(context);
        context.commit('SET_LOGS', logs.data.logs.items);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Logged and loaded');
        return logs;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async getIntegrationsQuery (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Loading integrations...');
      try {
        const integrations = await getIntegrations(context);
        context.commit('SET_INTEGRATIONS', integrations.data.integrations);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Logged and loaded');
        return integrations;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async revokeIntegrationAccessMutation (context, integrationObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Revoking integration access...');
      try {
        await revokeIntegration(context, integrationObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Logged and loaded');
        return 0;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    async authorizeIntegrationMutation (context, authorizationObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'Authorizing integration...');
      try {
        const integration = await authorizeIntegration(context, authorizationObj);
        context.commit('SET_INTEGRATIONS', integration.data.integrations);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'Logged and loaded');
        return integration;
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        return err;
      }
    },

    setIntegrationsErrors (context, errors) {
      context.commit('SET_INTEGRATIONS_ERRORS', errors);
    },

    setLogsErrors (context, errors) {
      context.commit('SET_LOGS_ERRORS', errors);
    },

    async updateUserMutation (context, queryObj) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'updating user');
      try {
        const user = await updateUser(context, queryObj);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'locked and loaded');
        return user;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
        return err;
      }
    },

    async deleteUserMutation (context) {
      context.commit('SET_LOADING_BOOL', true);
      context.commit('SET_LOADING_STATUS', 'deleting user');
      try {
        const user = await deleteUser(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', 'locked and loaded');
        return user;
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        context.commit('SET_LOADING_STATUS', err);
        console.log(err);
      }
    },

    async updateDeviceTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const types = await getDeviceTypes(context);
        context.commit('SET_LOADING_BOOL', false);
        if (this.state.brand.includes('Labs')) {
          context.commit('UPDATE_DEVICE_TYPES', types.data.deviceTypes);
        } else {
          context.commit('UPDATE_DEVICE_TYPES', types.data.__type.enumValues);
        }
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },

    async updateConsumablesTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const types = await getConsumablesTypes(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_CONSUMABLE_TYPES', types.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },

    async updateConsumablesStates (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const types = await getConsumablesStates(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_CONSUMABLE_STATES', types.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },

    async updateDeviceStatuses (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getDeviceStatuses(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_STATUSES', statuses.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },

    async updateOralBVoiceConfigs (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const voiceConfigs = await getOralBVoiceConfigs(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_VOICE_CONFIGS', voiceConfigs.data.__type.enumValues);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunHeadTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const heads = await getBraunHeadEnums(context);
        context.commit('UPDATE_HEAD_TYPES', heads.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunSessionStatus (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getBraunSessionStatusEnums(context);
        context.commit('UPDATE_SESSION_STATUS', statuses.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunSessionType (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const sessionTypes = await getBraunSessionTypeEnums(context);
        context.commit('UPDATE_SESSION_TYPE', sessionTypes.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunLogLevel (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const levels = await getBraunLogLevelEnums(context);
        context.commit('UPDATE_LOG_LEVEL', levels.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunClient (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const clients = await getBraunClientEnums(context);
        context.commit('UPDATE_CLIENT', clients.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateBraunSessionEnums ({ dispatch }) {
      dispatch('updateBraunHeadTypes');
      dispatch('updateBraunSessionStatus');
      dispatch('updateBraunClient');
      dispatch('updateBraunSessionType');
    },

    async updateOralBBrushingModeTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const modeTypes = await getOralBBrushingModeTypes(context);
        context.commit('UPDATE_BRUSHING_MODE_TYPES', modeTypes.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateGilletteFirmwareMode (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getGilletteFirmwareModel(context);
        context.commit('UPDATE_FIRMWARE_MODE', statuses.data.__type.enumValues);
        context.commit('SET_LOADING_BOOL', false);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },
    async updateGilletteHandleDocked (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getGilletteHandleDocked(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_HANDLE_DOCKED', statuses.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },

    async updateGilletteHandleMode (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getGilletteHandleMode(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_HANDLE_MODE', statuses.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },
    async updateGilletteCartridgeChangeTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getGilletteCartridgeChangeTypes(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_CARTRIDGE_CHANGE', statuses.data.__type.enumValues);
      } catch (err) {
        context.commit('SET_LOADING_BOOL', false);
        console.log(err);
      }
    },
    async updateGilletteDiagnosticResultTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getGilletteDiagnosticResultTypes(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_DIAGNOSTIC_RESULTS', statuses.data.__type.enumValues);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateTaskTypes (context) {
      context.commit('SET_LOADING_BOOL', true);
      try {
        const statuses = await getTaskTypes(context);
        context.commit('SET_LOADING_BOOL', false);
        context.commit('UPDATE_TASK_TYPES', statuses.data.__type.enumValues);
      } catch (err) {
        console.log(err);
        context.commit('SET_LOADING_BOOL', false);
      }
    },

    async updateUser (context, sessionInfo) {
      // if we have session info coming from app.js then use that
      if (sessionInfo) {
        context.commit('UPDATE_USER', sessionInfo);
      } else {
        // else we are coming from update user page and need to run auth again
        const session = await Auth.currentSession();
        const userInfo = await Auth.currentAuthenticatedUser({
          bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        context.commit('UPDATE_USER', { ...session, ...userInfo });
      }
      context.commit('SET_CONFIGS');
    },

    setSelectedDevice (context, deviceId) {
      const devices = this.state.devices;
      const device = devices.find(
        device => device.deviceId === deviceId
      );
      context.commit('SET_SELECTED_DEVICE', device);
    },

    updateSelectedDevice (context, UpdatedDevice) {
      context.commit('UPDATE_SELECTED_DEVICE', UpdatedDevice);
    },

    resetStore (context) {
      context.commit('RESET_STORE');
    }
  },
  getters: {
    loading: state => {
      return state.loading;
    },
    loadingStatusMessage: state => {
      return state.loadingStatus;
    },
    user: state => {
      return state.user;
    },
    devices: state => {
      return state.devices;
    },
    sessions: state => {
      return state.sessions;
    },
    deviceTypes: state => {
      return state.deviceTypes;
    },
    deviceStatuses: state => {
      return state.deviceStatuses;
    },
    deviceFirmwareModeTypes: state => {
      return state.gilletteFirmwareModeTypes;
    },
    deviceHandleDockedTypes: state => {
      return state.gilletteHandleDockedTypes;
    },
    deviceHandleModeTypes: state => {
      return state.gilletteHandleModeTypes;
    },
    deviceCartridgeChangeTypes: state => {
      return state.gilletteCartridgeChangeTypes;
    },
    deviceDiagnosticResultTypes: state => {
      return state.gilletteDiagnosticResultTypes;
    },
    deviceAnalyticsPreferencesTypes: state => {
      return state.braunAnalyticsPreferences;
    },
    deviceCreatedByTypes: state => {
      return state.braunCreatedBy;
    },
    deviceDeviceEventsTypes: state => {
      return state.braunDeviceEvents;
    },
    deviceVoiceConfigs: state => {
      return state.oralBVoiceConfigs;
    },
    deviceBrushingModeTypes: state => {
      return state.oralBBrushingModeTypes;
    },
    deviceHeadTypes: state => {
      return state.braunHeadTypes;
    },
    braunSessionStatus: state => {
      return state.braunSessionStatus;
    },
    braunSessionType: state => {
      return state.braunSessionType;
    },
    braunLogLevel: state => {
      return state.braunLogLevel;
    },
    taskTypes: state => {
      return state.taskTypes;
    },
    downloadSchema: state => {
      return state.downloadSchema;
    }
  }
});
