// ########## AGNOSTIC QUERIES ##########

// INTROSPECTION QUERY
export const GetSchemaQuery = `
query IntrospectionaQuery {
  __schema {
    queryType {
      name
    }
    mutationType {
      name
    }
    subscriptionType {
      name
    }
    types {
      kind
      name
      description
      fields(includeDeprecated: true) {
        name
        description
        args {
          name
          description
          type {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                      ofType {
                        kind
                        name
                        ofType {
                          kind
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          defaultValue
        }
        type {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                      ofType {
                        kind
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        isDeprecated
        deprecationReason
      }
      inputFields {
        name
        description
        type {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                      ofType {
                        kind
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        defaultValue
      }
      interfaces {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      enumValues(includeDeprecated: true) {
        name
        description
        isDeprecated
        deprecationReason
      }
      possibleTypes {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    directives {
      name
      description
      locations
      args {
        name
        description
        type {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                      ofType {
                        kind
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        defaultValue
      }
    }
  }
}

`;

// LOGS QUERIES
// statusCode breaks brands where it is not included in schema; used in Labs 09/10/20

export const GetLogsQuery = `query GetLogsQuery {
  logs(limit: 1000){
    items{
      consumerId
      message
      statusCode
      receivedTime
      deviceId
    }
  }
}`;

export const GetLogQuery = `query GetLogQuery($logTableFilterInput: TableLogFilterInput) {
  logs(filter: $logTableFilterInput) {
    items {
      message,
      receivedTime,
      statusCode
      deviceId
    }
  }
}`;

export const GetPendingJobs = `
query PendingJobs(
  $deviceId: String!
  $deviceType: DeviceType!
  ){
    pendingJobs(deviceId: $deviceId, deviceType: $deviceType) {
      executionSummaries {
        jobExectionSummary {
          executionNumber
          lastUpdatedAt
          queuedAt
          status
        }
        status
      }
    }
  }
  `;

export const GetAllJobs = `
  query AllJobs(
    $deviceId: String!
    $deviceType: DeviceType!
    ){
      allJobs(deviceId: $deviceId, deviceType: $deviceType) {
        executionSummaries {
          jobExectionSummary {
            description
            document
            executionNumber
            lastUpdatedAt
            queuedAt
            status
            jobId
          }
          status
        }
      }
    }
    `;

export const CancelJobMutation = `
    mutation cancelJob(
      $jobId: ID!
      $comment: String
      ){
        cancelJob(
          input: {
            jobId: $jobId,
            comment: $comment
          }
          )
        }
        `;

export const CreateOTA = `
        mutation createJob(
          $deviceId: ID!
          $deviceType: DeviceType!
          $fileName: String!
          $fileSize: String
          $md5: String
          $target: String
          $version: String!
          $force: Boolean,
          $operation: TaskType!
          ) {
            createJob(
              deviceId: $deviceId,
              deviceType: $deviceType,
              fileName: $fileName,
              fileSize: $fileSize,
              md5: $md5,
              target: $target,
              version: $version,
              force: $force,
              operation: $operation
              )
            }
            `;

export const GetTaskTypesQuery = `
            query types {
              __type(name: "TaskType") {
                enumValues {
                  name
                }
              }
            }
            `;

export const GetIntegrationsQuery = `query GetIntegrationsQuery {
            integrations{
              available{
                id
                loginUrl
                name
                sensor

              }
              configured{
                id
                name
                sensor
              }
            }
          }`;

export const AuthorizeIntegrationMutation = `
          mutation AuthorizeIntegrationMutation(
            $code: String!
            $grantType: GrantType!
            $integrationId: ID!
            ){
              authorizeIntegration(
                  code: $code,
                  grantType: $grantType
                  integrationId: $integrationId
                )
              }
              `;
export const RevokeIntegrationAccessMutation = `
          mutation RevokeIntegrationMutation(
            $integrationId: ID!
            ){
              revokeIntegration(integrationId: $integrationId)
            }`;

// FlightPlan Validation QUERIES:
export const GetValidationErrorsQuery = `query GetValidations{
  validations(limit: 1000, timeRange: SHORT) {
    time
    data {
      deviceId
      field
      fieldValue
      message
      table
      stateId
      sessionId
    }
  }
}`;
