// ########## ORALB QUERIES ##########
// User Details

export const UpdateOralBUser = `
mutation UpdateOralBUser(
  $input: UpdateUserInput
){
  updateUser(input: $input) {
    givenName
    familyName
    timezone
  }
}
`;

// delete user mutation
export const DeleteOralBUser = `mutation deleteUser{
  deleteUser
}
`;

// SESSIONS QUERIES:
export const GetBrushingSessionsQuery = `query sessions {
  sessions(start: "1970-01-01T00:00:00+00:00", end: "2999-01-01T00:00:00+00:00", limit: 1000){
    items {
      deviceId
      deviceType
      client
      clientVersion
      operatingSystem
      operatingSystemVersion
      parentDeviceId
      receivedTime
      thingName
      consumerId
      sessionId
      batteryLevel
      brushModel
      brushScore
      brushingDuration
      cleanTongue
      coveragePercentage
      flossTeeth
      gatewayModel
      gumBleeding
      handleSessionId
      onEventCount
      pressureDistribution
      pressureDuration
      pressureEventCount
      primaryBrushingMode
      rinseMouth
      sessionStartTime
      sessionType
      zonedBrushTime
      zonedPressureTime
    }
  }
}`;

export const DeleteOralBSessionMutation = `
mutation deleteSession($sessionId: String!) {
  deleteSession(sessionId:$sessionId)
}`;

export const CreateBrushingSessionMutation = `
mutation createSession(
  $batteryLevel: Int
  $brushingDuration: Int!
  $brushModel: Int!
  $brushScore: Int
  $cleanTongue: UserPoll
  $coveragePercentage: Float
  $deviceType: DeviceType!
  $deviceId: ID!
  $flossTeeth: UserPoll
  $gatewayModel: Int
  $gumBleeding: UserPoll
  $handleSessionId: Int!
  $onEventCount: Int!
  $pressureDistribution: String
  $pressureDuration: Int!
  $pressureEventCount: Int!
  $primaryBrushingMode: BrushingMode!
  $rinseMouth: UserPoll
  $sessionId: ID!
  $sessionStartTime: AWSDateTime!
  $sessionType: SessionType!
  $zonedBrushTime: AWSJSON
  ) {
    createSession( input: {
      sessionType: $sessionType
      deviceId: $deviceId
      deviceType: $deviceType
      sessionId: $sessionId
      batteryLevel: $batteryLevel
      brushScore: $brushScore
      brushingDuration: $brushingDuration
      cleanTongue: $cleanTongue
      coveragePercentage: $coveragePercentage
      flossTeeth: $flossTeeth
      sessionStartTime: $sessionStartTime
      gumBleeding: $gumBleeding
      handleSessionId: $handleSessionId
      gatewayModel: $gatewayModel
      brushModel: $brushModel
      onEventCount: $onEventCount
      pressureDistribution: $pressureDistribution
      pressureDuration: $pressureDuration
      pressureEventCount: $pressureEventCount
      primaryBrushingMode: $primaryBrushingMode
      rinseMouth: $rinseMouth
      zonedBrushTime: $zonedBrushTime
    }) {
      sessionType
      deviceId
      deviceType
      sessionId
      batteryLevel
      brushScore
      brushingDuration
      cleanTongue
      coveragePercentage
      flossTeeth
      sessionStartTime
      gumBleeding
      handleSessionId
      gatewayModel
      brushModel
      onEventCount
      pressureDistribution
      pressureDuration
      pressureEventCount
      primaryBrushingMode
      rinseMouth
      zonedBrushTime
    }
  }`;

// CONSUMABLES QUERIES:
export const GetConsumablesQuery = `
query getConsumables {
  consumables {
    items {
      consumableId
      consumableType
      deviceId
      startDate
      state
      drift
      totalSessionCount
      totalSessionDurationSeconds
    }
  }
}`;

export const CreateConsumableMutation = `
mutation createConsumable(
  $consumableType: ConsumableType!
  $deviceId: ID!
  $deviceType: DeviceType!
  $startDate: AWSDateTime!
  $drift: Int
) {
  createConsumable(
    deviceId: $deviceId
    deviceType: $deviceType
    consumableType: $consumableType
    startDate: $startDate
    drift: $drift
  ) {
    consumableId
    consumableType
    deviceId
    startDate
    state
    drift
    totalSessionCount
    totalSessionDurationSeconds
  }
}`;

export const UpdateConsumableMutation = `
mutation updateConsumable(
  $consumableId: ID!
  $consumableType: ConsumableType
  $state: ConsumableState
  $drift: Int
) {
  updateConsumable(
    consumableId: $consumableId
    consumableType: $consumableType
    state: $state
    drift: $drift
  ) {
    consumableId
    consumableType
    startDate
    state
    drift
    totalSessionCount
    totalSessionDurationSeconds
  }
}`;

// DEVICES QUERIES:
export const GetOralBDevicesQuery = `query GetDevices{
  devices{
    deviceType
    deviceId
    macAddress
    ringColor
    ssid
    name
    status
    protocolVersion
    firmwareVersion
    brushHeadDate
    voiceAssistantConfigured
    rssi
  }
}`;

export const GetOralBDeviceQuery = `query GetDevice($deviceId: String!){
  device(deviceId: $deviceId){
    deviceType
    deviceId
    macAddress
    ringColor
    ssid
    name
    status
    protocolVersion
    firmwareVersion
    brushHeadDate
    voiceAssistantConfigured
    rssi
  }
}`;

export const CreateOralBDeviceMutation = `
mutation CreateDevice(
  $deviceId: ID!,
  $deviceType: DeviceType!
) {
  createDevice(
    deviceId: $deviceId,
    deviceType: $deviceType
  ) {
    cert,
    privateKey
  }
}`;

export const UpdateOralBDeviceMutation = `
  mutation update(
    $device: UpdateDeviceInput!,
  ){
    updateDevice(
      input:$device
    ){
      deviceId
      deviceType
      macAddress
      ringColor
    }
  }`;

export const DeleteOralBDeviceMutation = `
mutation DeleteDeviceMutation(
  $deviceId: ID!,
  $deviceType: DeviceType!
) {
  deleteDevice(
    deviceId: $deviceId,
    deviceType: $deviceType
  )
}`;

export const GetOralBDeviceTypesQuery = `
query types {
  __type(name: "DeviceType") {
    enumValues {
      name
    }
  }
}`;

export const GetOralBDeviceStatusesQuery = `
query types {
  __type(name: "DeviceStatus") {
    enumValues {
      name
    }
  }
}`;

export const GetOralBVoiceAssistQuery = `
query types {
  __type(name: "VoiceAssistantStatus") {
    enumValues {
      name
    }
  }
}
`;

export const GetOralBBrushingModeTypesQuery = `
query types {
  __type(name: "BrushingMode") {
    enumValues {
      name
    }
  }
}

`;
export const GetConsumableTypesQuery = `
query types {
  __type(name: "ConsumableType") {
    enumValues {
      name
    }
  }
}
`;

export const GetConsumableStateQuery = `
query types {
  __type(name: "ConsumableState") {
    enumValues {
      name
    }
  }
}
`;
